import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "ok", "ng", "ok_count", "ng_count" ]
  static values = { spot: Number }

  connect() {
  }

  counter(el) {
    const button_type = el.target.dataset.voteTarget
    const payload = new FormData()
    payload.append('button_type', button_type)
    payload.append('method', 'up')
    this.okTarget.disabled = true
    this.ngTarget.disabled = true
    if (button_type == "ok"){
      ++this.ok_countTarget.innerText
    } else {
      ++this.ng_countTarget.innerText
    }
    $(".shopThanks").show(0);
    $.ajax({
      url: '/spots/' + this.spotValue + '/counter_change',
      type: 'POST',
      cache: false,
      contentType: false,
      processData: false,
      data: payload,
      dataType: 'json',
      error: (_response, _status, xhr) => {
        Turbolinks.visit(location.pathname)
      },
    })
  }
}
