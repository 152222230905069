import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ]
  static classes = [ "added" ]
  static values = { spot: Number }

  connect() {
    const spot_id = String(this.spotValue)
    const cookie_name = 'favorited_spots'
    var favorited_ids = []
    if(Cookies.get(cookie_name)){
      favorited_ids = Cookies.get(cookie_name).split(",")
      // 既にCookieに存在する場合は要素の書き換えを行う
      if($.inArray(spot_id, favorited_ids) !== -1){
        this.button_disabled()
      }
    }
  }

  add() {
    // ボタン要素を書き換え
    this.button_disabled()
    // モーダルを表示
    this.modal_show()
    // Cookieへの追加処理
    const spot_id = String(this.spotValue)
    const cookie_name = 'favorited_spots'
    var favorited_ids = []
    // 既にCookieが存在している場合は、Cookieの内容を配列にして格納
    if(Cookies.get(cookie_name)){
      favorited_ids = Cookies.get(cookie_name).split(",")
    }
    // 既にCookie上にIDが存在する場合は一度削除してもう一度追加して順番を変更
    if($.inArray(spot_id, favorited_ids) !== -1){
      favorited_ids = favorited_ids.filter(n => n !== spot_id)
      favorited_ids.push(spot_id)
    // 存在していなければ、spot_idを配列に追加
    } else {
      favorited_ids.push(spot_id)
    }
    // 30個を超えた場合には1つ削除
    if (favorited_ids.length > 30){
      favorited_ids.shift()
    }
    // 配列をストリングに変換してCookieに保存
    Cookies.set(cookie_name, favorited_ids.join(','))
  }

  button_disabled() {
    // ボタンに表示されているテキストを変更
    this.buttonTarget.innerText = "お気に入りに追加済み"
    // ボタンを無効化
    this.buttonTarget.disabled = true
    this.buttonTarget.classList.add(this.addedClass)
  }

  modal_show() {
    //モーダルの要素を探す
    var modalThis = $('body').find("#posted_modal");
    //bodyの最下にwrapを作る
    $('body').append('<div id="modalWrap" />');
    var wrap = $('#modalWrap'); wrap.fadeIn('200');
    modalThis.fadeIn('200');
    //モーダルの高さを取ってくる
    function mdlHeight() {
      var wh = $(window).innerHeight();
      var attH = modalThis.find('.modalInner').innerHeight();
      modalThis.css({ height: attH });
    }
    mdlHeight();
    $(window).on('resize', function () {
      mdlHeight();
    });
    function clickAction() {
      modalThis.fadeOut('200');
      wrap.fadeOut('200', function () {
        wrap.remove();
      });
    }
    //wrapクリックされたら
    wrap.on('click', function () {
      clickAction(); return false;
    });
    //3秒後に消える
    setTimeout(clickAction, 3000); return false;
  }
}
