import {
  Controller
} from "stimulus"

export default class extends Controller {
  static targets = ["map", "latitude", "longitude", "postal", "address", "prefecture", "city", "submit", "thanks"]
  static classes = [ "posted" ]

  connect() {
    const stimulusThis = this
    let latitude = 35.68421096423828
    let longitude = 139.77450370788574
    // GeoLocation APIに対応している場合
    if (navigator.geolocation) {
      //現在位置取得
      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      function success(position) {
        latitude = position.coords.latitude
        longitude =  position.coords.longitude
        stimulusThis.initializeMap(latitude, longitude)
      }

      function error(err) {
        console.warn(`ERROR(${err.code}): ${err.message}`)
      }

      navigator.geolocation.getCurrentPosition(success, error, options);
    } else {
      stimulusThis.initializeMap(latitude, longitude)
    }
  }

  initializeMap(latitude, longitude){
    const stimulusThis = this
    var objMarker;
    var objMap;
    var latlng = new google.maps.LatLng(latitude, longitude);

    var optMap = {
      zoom: 16,
      center: latlng,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      scaleControl: true
    };
    objMap = new google.maps.Map(this.mapTarget, optMap);

    //初期位置の住所取得
    this.getAddress(latlng);

    //マーカー
    objMarker = new google.maps.Marker({
      position: latlng,
      map: objMap
    });

    // マップクリックイベントを追加
    google.maps.event.addListener(objMap, 'click', function (e) {
      // ポジションを変更
      objMarker.setPosition(e.latLng);
      // 地図の中心をマーカーの位置に変更
      //objMap.setCenter(e.latLng);
      objMap.panTo(e.latLng);
      // 住所取得
      stimulusThis.getAddress(e.latLng);
    });
  }

  getAddress(position) {
    const stimulusThis = this
    var geocoder = new google.maps.Geocoder();
    var postalcode = "";
    var prefecture = "";
    var locality = "";
    var level1 = "";
    var level2 = "";
    var level3 = "";
    var level4 = "";
    var level5 = "";
    var premise = "";
    var subpremise = "";
    var street = "";

    //緯度/経度書き出し
    this.latitudeTarget.value = position.lat();
    this.longitudeTarget.value = position.lng();

    geocoder.geocode({
      'latLng': position,
      'language': 'ja'
    }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        if (results[1]) {
          //住所を加工してinput要素に設定
          stimulusThis.addressTarget.value = results[0].formatted_address.replace(/^日本、〒[0-9]{3}-[0-9]{4} /, '');
          for (var i = 0; i < results[0].address_components.length; i++) {
            for (var j = 0; j < results[0].address_components[i].types.length; j++) {
              if (results[0].address_components[i].types[j] == "postal_code") {
                postalcode = results[0].address_components[i].short_name;
              }
              if (results[0].address_components[i].types[j] == "administrative_area_level_1") {
                prefecture = results[0].address_components[i].short_name;
              }

              //郡がadministrative_area_level_2、市区町村がlocality
              if (results[0].address_components[i].types[j] == "administrative_area_level_2") {
                locality = results[0].address_components[i].short_name;
              }
              if (results[0].address_components[i].types[j] == "locality") {
                locality = locality + results[0].address_components[i].short_name;
              }

              if (results[0].address_components[i].types[j] == "sublocality_level_1") {
                level1 = results[0].address_components[i].short_name;
              }
              if (results[0].address_components[i].types[j] == "sublocality_level_2") {
                level2 = results[0].address_components[i].short_name;
              }
              if (results[0].address_components[i].types[j] == "sublocality_level_3") {
                level3 = results[0].address_components[i].short_name;
              }
              if (results[0].address_components[i].types[j] == "sublocality_level_4") {
                level4 = results[0].address_components[i].short_name;
              }

              if (results[0].address_components[i].types[j] == "premise") {
                premise = results[0].address_components[i].short_name;
              }
              if (results[0].address_components[i].types[j] == "subpremise") {
                subpremise = results[0].address_components[i].short_name;
              }
            }
          }

          //番地を繋ぐハイフン
          if (isAllNumber(level3) & level3 !== "" && isAllNumber(level4) && level4 !== "") {
            level4 = "−" + level4;
          }

          street = level3 + level4;

          //地方などで「番地1」が空で「番地2」が存在する場合は番地2を番地1に付け替える
          if (street == "" && premise !== "") {
            street = premise;
            premise = "";
          }

          //住所2(premise)が番地の数字だけの場合は「番地1」に追記する形にする
          if (isAllNumber(premise) && premise !== "") {
            street = street + "−" + premise;
            premise = "";
          }

          //建物名があればpremiseに追加
          premise = premise + " " + subpremise;

          //郵便番号・都道府県・市区町村を書き込み
          stimulusThis.postalTarget.value = postalcode;
          stimulusThis.prefectureTarget.value = prefecture;
          stimulusThis.cityTarget.value = locality;
        } else {
          stimulusThis.addressTarget.value = '取得できませんでした';
        }
      } else {
        stimulusThis.addressTarget.value = 'エラー: 取得できませんでした';
      }
    });
    function isAllNumber(val) {
      var ptn = /^[0-9０-９]*$/;
      return ptn.test(val);
    }
  }
  submit() {
    console.log("Submit")
    this.submitTarget.value = "送信しました";
    this.submitTarget.classList.add(this.postedClass);
    this.submitTarget.disabled = true;
    this.thanksTarget.style.display = "block";
  }
  ajax_success() {
    this.submit
  }
}