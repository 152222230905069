import { Controller} from "stimulus"

export default class extends Controller {
  static targets = ["map"]

  connect() {
    if (typeof (google) != "undefined"){
      this.initializeMap()
    }
  }

  initializeMap(call_from, current_latitude, current_longitude) {
    this.map()
    let currentWindow;
    if (this.spots == undefined) {
      this.spots = gon.spots
    }
    if (call_from == "setCenter") {
      this.spots.unshift({name: "現在地", latitude: current_latitude, longitude: current_longitude})
    }
    this.spots.map((spot, index) => {
      this.marker().setPosition({lat: parseFloat(spot.latitude), lng: parseFloat(spot.longitude)})
      this.marker().setVisible(true)
      if (call_from == "setCenter" && index == 0){
        var marker = new google.maps.Marker({
          map: this.map(),
          position: {lat: parseFloat(spot.latitude), lng: parseFloat(spot.longitude)},
          icon: '/images/current_marker.png',
        })
      } else {
        var marker = new google.maps.Marker({
          map: this.map(),
          position: {lat: parseFloat(spot.latitude), lng: parseFloat(spot.longitude)},
          //icon: '/images/marker_' + spot.category + '.png',
        })
      }
      if (call_from != "setCenter" || index != 0){
        marker.addListener('click', () => {
          currentWindow && currentWindow.close();
          const mapUrl = '/search/city-' + spot.city_id + '/station-' + spot.station_id + '/' + spot.id
          const content = '<a href="' + mapUrl + '" rel="noopener noreferrer">' + spot.name + '</a>'
          const infoWindow = new google.maps.InfoWindow({content: content});
          infoWindow.open(this.map(), marker);
          currentWindow = infoWindow;
        })
      }
    })
  }

  map() {
    if(this._map == undefined) {
      this._map = new google.maps.Map(this.mapTarget, {
        center: new google.maps.LatLng(
          parseFloat(gon.latitude),
          parseFloat(gon.longitude)
        ),
        zoom: 15
      })
    }
    return this._map
  }

  marker() {
    if (this._marker == undefined) {
      this._marker = new google.maps.Marker({
        map: this.map(),
        anchorPoint: new google.maps.Point(0,0)
      })
      let mapLocation = {
        lat: parseFloat(gon.latitude),
        lng: parseFloat(gon.longitude)
      }
      this._marker.setPosition(mapLocation)
      this._marker.setVisible(true)
    }
    return this._marker
  }

  setCenter() {
    const stimulusThis = this
    var dfd = $.Deferred();
    if (!window.navigator.geolocation) dfd.reject();
    window.navigator.geolocation.getCurrentPosition(
      function (position) {
        dfd.resolve(position);
        var coordinate = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        stimulusThis.map().setCenter(coordinate);
        $.ajax({
          url: "/spot_request",
          type: "GET",
          data: 'latitude=' + position.coords.latitude + '&longitude=' + position.coords.longitude
        })
        .done(function (data, textStatus, jqXHR) {
          stimulusThis.spots = data.spots;
          stimulusThis.initializeMap("setCenter", position.coords.latitude, position.coords.longitude)
        })
        .fail(function() {
          console.log("エラーが発生しました。");
        });
      },
      function (error) {
        alert('喫煙所を正確に検索するために、位置情報の許可をオススメします。\n\nスマートフォンの設定から位置情報の許可をお願い致します。')
        dfd.reject();
      },
      {
        enableHighAccuracy: true,
        timeout: 10000,
        maximumAge: 600000
      }
    );
    return dfd.promise();
  }
}