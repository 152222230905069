import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "form_modal", "button", "content", "submit" ]
  static classes = [ "posted" ]
  static get values() {
    return { enable: Boolean };
  }

  connect() {
    this.contentTarget.addEventListener('input', this, false);
  }

  disconnect() {
    this.contentTarget.removeEventListener('input', this, false);
  }

  handleEvent(event) {
    switch (event.type) {
      case 'input':
        this.enableValue = this.contentTarget.value.length > 0;
        break;
    }
  }

  enableValueChanged() {
    this.submitTarget.disabled = !this.enableValue;
  }

  submit() {
    this.form_hide()
    this.buttonTarget.innerText = "投稿ありがとうございました！";
    this.buttonTarget.classList.add(this.postedClass);
    this.buttonTarget.disabled = true;
  }

  form_show() {
    this.form_modalTarget.style.display = "block";
    $('#modal-bg').fadeIn();
    $('body').addClass("all_scrolling_for");
  }

  form_hide() {
    this.form_modalTarget.style.display = "none";
    $('#modal-bg').fadeOut();
    $('body').removeClass("all_scrolling_for");
  }
}